@import url("https://fonts.googleapis.com/css2?family=Forum&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Palanquin:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

body {
  font-family: "Palanquin";
  color: #2d2d2d;
  background-color: #f2f2f2;
}

/*Scrollbar*/
/* width */
::-webkit-scrollbar {
  display: block;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #2e2e2e00;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(185, 185, 185);
}

a {
  color: #151515;
  text-decoration: none;
}

p {
  color: #0e0e0eaf;
}

a:hover {
  color: #ff8486;
  text-decoration: none;
}

:root {
  --primary: #fea116;
  --light: #f1f8ff;
  --dark: #0f172b;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Button ***/
.btn {
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
  border: none;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;

  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 2px;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #1d9c09;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #e65d5f;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  position: fixed;
  background-color: #00000000;
  transition: background-color 0.7s;
  height: 10vh;
  padding: 10vh 0 0 0;
  color: white;
}

#header.header-scrolled {
  padding: 12px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header .logo {
  font-size: 10px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Palanquin", sans-serif;
}

#header .logo a {
  color: #ffffff;
}

#header .logo a span {
  color: #1d9c09;
}

#header .logo img {
  max-height: 50px;
}

#header2 {
  position: fixed;
  background-color: #1d1d1d;
  transition: background-color 0.9s;
  height: 10vh;
  padding: 10.2vh 0 0 0;
  color: white;
}

#header2.header-scrolled {
  padding: 12px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#header2 .logo {
  font-size: 10px;
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-family: "Palanquin", sans-serif;
}

#header2 .logo a {
  color: #ffffff;
}

#header2 .logo a span {
  color: #1d9c09;
}

#header2 .logo img {
  max-height: 50px;
}

/*--------------------------------------------------------------
# Get Startet Button
--------------------------------------------------------------*/
.get-started-btn {
  margin-left: 30px;
  background: #1d9c09;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}

.get-started-btn:hover {
  background: #111111;
  color: #fff;
}

@media (max-width: 992px) {
  .get-started-btn {
    margin: 0 15px 0 0;
    padding: 6px 18px;
  }
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-family: "Palanquin", sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #ffffff;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #1d9c09;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 30px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  color: #121212;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #1d9c09;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #111111;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #111111;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #1d9c09;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #1d9c09;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*** About us Title ***/
.aboutus h1 {
  font-weight: 600;
}

.textprimary {
  color: #474747;
  font-family: "Forum";
}

.btn-primary {
  background: #1d9c09;
  color: #fff;
  border-radius: 4px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
}

.btn-primary:hover {
  background: #111111;
  color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/

.heading {
  font-weight: 800;
  color: #474747;
}
#hero {
  width: 100%;
  height: 100vh;
  background: url("./Components/assets/img/hero1.webp") top center no-repeat;
  background-size: cover;
  position: relative;
  padding-top: 28vh;
}

#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.611);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}

/* #hero h1 {
  margin: 0;
  font-size: 55px;
  font-weight: 700;
  color: #fff;
}

#hero h2 {
  color: #fff;
  margin: 10px 0 0 0;
  line-height: 28px;
  letter-spacing: 0.6px;
  font-size: 20px;
} */

/* #hero .btn-get-started {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 4px;
  transition: 0.5s;
  margin-top: 30px;
  color: #fff;
  background: #1d9c09;
  border: 2px solid #1d9c09;
} */

/* #hero .btn-get-started:hover {
  background: transparent;
  border-color: #fff;
} 

 */
@media (max-width: 768px) {
  #hero {
    text-align: left;
    padding-top: 13vh;
    height: 58vh;
  }

  #hero h1 {
    font-size: 27px;
    line-height: 15px;
  }

  #hero h2 {
    font-size: 12px;
    line-height: 24px;
  }
}

@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/

.flow {
  --flow-space: var(--metric-rhythm);
}

.flow > * + * {
  margin-top: 1em;
  margin-top: var(--flow-space);
}

/**
* CARDS
*/

.card {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
  border: 2px solid var(--color-shadow);
  border-radius: 6px;
  background-color: var(--bg-white, #fff);

  .card__img {
    position: relative;
    .img__cover {
      height: 11rem;
    }

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }

  .card__text {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    -webkit-box-ordinal-group: 3;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 1rem;
    margin-top: -15px;

    > * + * {
      margin-top: 1rem;
    }

    > :last-child {
      margin-top: auto;
      padding-top: 0.75rem;
    }

    p {
      max-width: 50ch;
      font-weight: 400;
      font-size: 0.8em;
      color: grey;
    }

    h2 {
      font-size: 1em;
      font-weight: 700;
      margin-top: 1.2rem;

      a {
        text-decoration: none;
      }
    }
  }
}

.card2 {
  background-color: #e2e2e2;

  padding: 10px 16px 16px 16px;
  border-radius: 15px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.25s;
  border: none;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 15px;
    background-color: var(--card-background-color);
  }

  .icon {
    z-index: 2;
    position: relative;
    display: table;
    padding: 8px;

    &::after {
      content: "";
      position: absolute;
      inset: 4.5px;
      border-radius: 50%;
      background-color: var(--card-icon-background-color);
      border: 1px solid var(--card-icon-border-color);
      backdrop-filter: blur(2px);
      transition: background-color 0.25s, border-color 0.25s;
    }
  }

  h4 {
    z-index: 2;
    position: relative;
    margin: 12px 0 4px 0;
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
    line-height: 2;
    color: var(--card-label-color);
  }

  p {
    z-index: 2;
    position: relative;
    margin: 0;
    font-size: 14px;
    line-height: 1.7;
    color: rgb(32, 32, 32);
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
  color: #313131;
  text-align: center;
  background-color: #e8e8e8;
  padding: 30px 0 32px 0;
  border-radius: 4px;
}

.contact .info-box i {
  font-size: 32px;
  color: #ffffff;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #323232;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  padding: 30px;
  border-radius: 4px;
  background-color: #e8e8e8;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form .form-group {
  margin-bottom: 25px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #111111;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #ffffff;
  border: 0;
  padding: 10px 32px;
  color: #000000;
  transition: 0.4s;
  border-radius: 4px;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #000000;
  color: white;
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* $animationSpeed: 40s; */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.slider {
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: auto;

  .slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
  }
  .slide {
    height: 100px;
    width: 250px;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: #fff;
  font-size: 14px;
  background: #111111;
}

#footer p {
  color: rgb(184, 184, 184);
}

#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #1b1b1b;
}

#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 26px;
  line-height: 1;
  font-weight: 700;
}

#footer .footer-top .footer-contact h3 span {
  color: #1d9c09;
}

#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  font-family: "Palanquin", sans-serif;
}

#footer .footer-top h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-top h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: #1d9c09;
  bottom: 0;
  left: 0;
}

#footer .footer-top .footer-links {
  margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: white;
  font-size: 18px;
  line-height: 1;
}

#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}

#footer .footer-top .footer-links ul a {
  color: #aaaaaa;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #fff;
}

#footer .footer-newsletter {
  font-size: 15px;
}

#footer .footer-newsletter h4 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}

#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 5px 10px;
  position: relative;
  border-radius: 4px;
  text-align: left;
}

#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-newsletter form button {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #1d9c09;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-newsletter form input[type="submit"]:hover {
  background: #1d9c09;
  background-color: rgba(100, 148, 237, 0.469);
}

#footer .credits {
  padding-top: 5px;
  font-size: 13px;
}

#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #2b2b2b;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .social-links a:hover {
  background: #1d9c09;
  color: #fff;
  text-decoration: none;
}

#CsrCard {
  background-color: #ffffff;
  border: 1px solid rgb(222, 222, 222);
  color: #2f2f2f;
  height: 10vh;
}

#CsrCard p {
  font-size: 12px;
  color: #a2a1a1;
  margin-top: -3px;
}

.csrHead {
  font-weight: bold;
  font-size: 15px;
  color: #1d9c09;
}

.content > div {
  padding-bottom: 74px;
}

.hero__background {
  width: 100%;
}

.background__text {
  position: absolute;
  top: 10;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 300px;
  letter-spacing: 25px;
  font-weight: 700;
  overflow: hidden;
  color: #ffffff;
  opacity: 0.06;

  span {
    animation: slideleft 40s linear;
    animation-fill-mode: forwards;
  }
}

.background__tile {
  position: absolute;
  bottom: -50px;
  right: 0;

  rect:nth-child(1),
  rect:nth-child(4),
  rect:nth-child(6),
  rect:nth-child(10) {
    animation: tilefade 5s 2.5s ease-in-out infinite both;
    opacity: 0.05;
  }

  rect:nth-child(2),
  rect:nth-child(5),
  rect:nth-child(9) {
    animation: tilefadetwo 7.5s ease-in-out infinite both;
    opacity: 0.1;
  }

  rect {
    animation: tilefadethree 2.5s 5s ease-in-out infinite both;
    opacity: 0.15;
  }

  rect:nth-child(8) {
    opacity: 0.2;
    animation: tilefadefour 5s ease-in-out infinite both;
  }
}

.background__wave {
  position: absolute;
  bottom: -35px;
  left: 0;
}

.hero__content {
  text-align: center;
  padding: 26px 0 170px;
  margin: 0 auto;
  max-width: 1804px;
}

.hero__label {
  position: relative;
  left: 32px;
  top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 10px;
  font-weight: 700;
  width: 322px;
  animation: fadeleft 1s ease-in-out;

  div {
    background-color: black;
    height: 1px;
    width: 141px;
  }
}

.hero__title {
  font-size: 50px;
  letter-spacing: 0.9px;
  font-family: "Forum", sans-serif;
  font-weight: 900;
  animation: faderight 1s ease-in-out forwards;
  color: white;
  margin-top: 65px;
  z-index: -2;

  span {
    background: linear-gradient(to right, rgba(193, 217, 203, 0.4), #ffffff);
    background-clip: text;
    color: transparent;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
}

/* .hero__title {
  color: white;
  font-size: 70px;

  margin-bottom: 48px;
  opacity: 0;
  animation: fadeup 0.75s 1s ease-in-out forwards;
} */

.hero__subtitle {
  color: rgb(198, 198, 198);
  font-size: 22px;
  line-height: 32px;
  font-family: "Palanquin";
  letter-spacing: 0.5px;
  max-width: 880px;
  margin: 0 auto;
  margin-bottom: 48px;
  opacity: 0;
  animation: fadeup 0.75s 1s ease-in-out forwards;
}

@media (max-width: 768px) {
  .hero__title {
    font-size: 34px;
    letter-spacing: 1px;
    margin: 50px 3px 5px 3px;
    line-height: 50px;
    font-weight: 800;
    text-align: center;
  }

  .hero__subtitle {
    font-size: 15px;
    line-height: 21px;
    text-align: center;
    margin: 15px;
  }
}

@media (max-height: 500px) {
  #hero__title {
    height: 120vh;
  }
}

.hero__scroll {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  letter-spacing: 1px;
  cursor: pointer;
  opacity: 0;
  animation: fadeup 0.75s 1.5s ease-in-out forwards;

  span {
    margin-bottom: 6px;
  }

  svg {
    animation: bounce 2s ease infinite;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(16px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideleft {
  0% {
    transform: translateX(15%);
  }
  50% {
    transform: translateX(-15%);
  }
  100% {
    transform: translateX(-7.5%) translateY(-7.5%) scale(0.75);
  }
}

@keyframes fadeleft {
  0% {
    opacity: 0;
    transform: translateX(32px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes faderight {
  0% {
    opacity: 0;
    transform: translateX(-32px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translateY(32px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes tilefade {
  0% {
    opacity: 0.05;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.05;
  }
}

@keyframes tilefadetwo {
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes tilefadethree {
  0% {
    opacity: 0.15;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.15;
  }
}

@keyframes tilefadefour {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.2;
  }
}

#svgg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.contactt-form {
  width: 100%;
}
input {
  width: 100%;
}
textarea {
  width: 100%;
}

.cube1,
.cube2 {
  height: 90vh;
  background-color: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@media (max-width: 778px) {
  #about_img1 {
    display: none;
  }
}

@media (min-width: 778px) {
  #about_img2 {
    display: none;
  }
}

@media (max-width: 778px) {
  #pccover {
    display: none;
  }
}

@media (min-width: 778px) {
  #mbcover {
    display: none;
  }
}

.initechNode {
  padding: 5px 15px;
  border: 1px solid #d5d5d5;
  display: inline-block;
  text-align: center;
  background-color: #fdfdfd;
  border-radius: 5px;
}

.middle-level {
  background-color: #3789b8;
  margin: 2px;
  color: white;
}

.product-dept {
  background-color: #efba66;
  margin: 2px;
}

.rd-dept {
  background-color: #d2d6de;
  margin: 2px;
}

.pipeline1,
.frontend1 {
  background-color: #5cbc4d;
  margin: 2px;
}

.mainHeadChart {
  background-color: #2e2e2e;
  font-size: 18px;
  color: white;
}

.title {
  font-weight: bold;
  font-size: 12px;
}

.fullname {
  font-style: italic;
  font-size: 12px;
}

/* $opensans: "Open Sans", sans-serif;
$blue: #3498db;
$blue--hover: #2980b9;
$cloud: #ecf0f1;
$cloud--hover: #bdc3c7;
$transition: all 146ms ease;
$shadow: 0 30px 60px -30px rgba(0, 0, 0, 0.5);
$radius: 3px; */

.login_box {
  align-self: center;
  background-color: #fefefe;
  border: 1px solid #f2f2f2;
  border-radius: 3px;
  box-shadow: 0 30px 60px -30px rgba(0, 0, 0, 0.5);
  min-width: 500;
  max-width: 450px;
  padding: 2rem;

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 1em 0 0 0;

    button {
      margin-left: 16px;
    }
  }
}
.LoginContainer {
  width: 400px;
}

.MainContainer {
  height: calc(100vh - 40px);
  width: calc(100vw - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.CompanyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.CompanyLogoConatiner {
  padding-right: 10px;
}

.CompanyLogoImg {
  height: 60px;
}

.CompanyName {
  font-size: 30px;
  font-weight: bolder;
}

.LoginHeaderTitle {
  font-size: 25px;
  padding-bottom: 5px;
  font-weight: bold;
}

.FormContainer {
  padding-top: 20px;
  padding-bottom: 20px;
  height: max-content;
}

.FormContainer .InputContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  height: max-content;
}

.InputContainer .label {
  display: block;
  font-weight: normal;
  font-size: 14px;
  padding-bottom: 5px;
}

.InputContainer .PasswordLabel {
  font-weight: bold;
  color: gray;
  font-size: 13px;
  padding-top: 5px;
}

.ForgotPasswordContainer {
  color: red;
  cursor: pointer;
}

.ButtonsContainer .SignInButtonContainer .SigninButton:hover {
  background-color: #2863b4;
}

.ButtonsContainer .SignInButtonContainer .SigninButton {
  width: 100%;
  background-color: #2d70ce;
  color: white;
  border-radius: 10px;
  border: 0px;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
}

.ButtonsContainer .OthersButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}

.ButtonsContainer .OthersButtonContainer button {
  width: 48%;
  font-size: 15px;
  padding: 10px;
  cursor: pointer;
  border-radius: 7px;
  border: 0px;
}

.FooterContainer {
  padding-top: 30px;
  text-align: center;
}

/* Our Team
-------------------------------------------------------*/

.our-team .team-row {
  margin-left: -40px;
  margin-right: -40px;
}

.our-team .team-wrap {
  padding: 0 40px;
}

.our-team .container-fluid {
  padding: 0 50px;
}

.team-img img {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  width: 90%;
}

.team-member,
.team-img {
  position: relative;
  overflow: hidden;
}

.team-title {
  margin: 20px 0 7px;
  font-size: 23px;
  font-weight: bold;
  color: #1d9c09;
}

.overlay {
  background-color: rgba(20, 20, 20, 0.7);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.team-details {
  opacity: 0;
  position: absolute;
  top: 35%;
  left: 0;
  padding: 5%;
  overflow: hidden;
  width: 100%;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.team-details p {
  color: #fff;
  font-size: 14px;
}

.team-img:hover .team-details {
  opacity: 1;
  margin-top: -80px;
}

.team-img:hover .overlay {
  opacity: 1;
}

.socials a {
  display: inline-block;
  width: 37px;
  height: 37px;
  background-color: transparent;
}

.socials i {
  line-height: 37px;
  color: #616161;
  font-size: 14px;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.team-details .socials i {
  color: #fff;
}

.socials a:hover i {
  color: #fff;
  background-color: #355c7d;
}

/*counters*/

.sectionClass {
  padding: 20px 0px 50px 0px;
  position: relative;
  display: block;
}

.fullWidth {
  width: 100% !important;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}

.sectiontitle h2 {
  font-size: 30px;
  color: #222;
  margin-bottom: 0px;
  padding-right: 10px;
  padding-left: 10px;
}

.headerLine {
  width: 160px;
  height: 2px;
  display: inline-block;
  background: #101f2e;
}

.projectFactsWrap {
  display: flex;
  margin-top: 30px;
  flex-direction: row;
  flex-wrap: wrap;
}

#projectFacts .fullWidth {
  padding: 0;
}

.projectFactsWrap .item {
  width: 33%;
  height: 100%;
  padding: 50px 0px;
  text-align: center;
  margin: 3px;
}

.projectFactsWrap .item:nth-child(1) {
  background: url("./Components/assets/img/countbg.webp") no-repeat center
    center;
  background-size: cover;
  width: 32%;
}

.projectFactsWrap .item:nth-child(2) {
  background: url("./Components/assets/img/countbg.webp") no-repeat center
    center;
  background-size: cover;
  width: 32%;
}

.projectFactsWrap .item:nth-child(3) {
  background: url("./Components/assets/img/countbg.webp") no-repeat center
    center;
  background-size: cover;
  width: 32%;
}

.projectFactsWrap .item:nth-child(4) {
  background: rgb(23, 44, 66);
}

.projectFactsWrap .item p.number {
  font-size: 40px;
  padding: 0;
  font-weight: bold;
}

.projectFactsWrap .item p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  margin: 0;
  padding: 10px;
}

.projectFactsWrap .item span {
  width: 60px;
  background: rgba(255, 255, 255, 0.8);
  height: 2px;
  display: block;
  margin: 0 auto;
}

.projectFactsWrap .item i {
  vertical-align: middle;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.8);
}

.projectFactsWrap .item:hover i,
.projectFactsWrap .item:hover p {
  color: white;
}

.projectFactsWrap .item:hover span {
  background: white;
}

@media (max-width: 786px) {
  .projectFactsWrap .item {
    flex: 0 0 100%;
  }
}

/* CSR */

.csraside {
  position: relative;
  float: left;
  width: 30%;
  height: 100%;
  background-color: #fff;
}
/* Do not allow image dragging */
.csraside .mask {
  display: block;
  position: absolute;
  z-index: 30;
  top: -2.5%;
  left: -2.5%;
  width: 105%;
  height: 105%;
  background: transparent;
}
.csraside img {
  display: block;
  position: absolute;
  z-index: 0;

  min-width: 150px;
  height: 100%;
}

.csraside .overlay {
  overflow: hidden;
  position: absolute;
  z-index: -1;
  top: -2.5%;
  left: -10%;
  width: 120%;
  height: 110%;
}

.csraside .overlay img {
  display: none;
}

.csrsection {
  overflow: hidden;
  /* Simple support for IE6-7: images gallery are shown verticaly but all keeps accessible and fonctional */
  overflow-y: auto;

  position: relative;
  float: left;
  width: 70%;
  height: 100%;
  padding: 5% 2%;
  background: #fff;
  margin-bottom: 20vh;
}
/*! Note: @supports query isn't a cross browsers solution */
@supports (display: -webkit-box) or (display: box) or (display: -moz-flex) or
  (display: -ms-flex) or (display: -webkit-flex) or (display: flex) {
  .csraside .overlay {
    overflow: hidden;
    content: " ";

    position: absolute;
    top: -2.5%;
    left: -2.5%;

    height: 105%;
    width: 105%;

    background-size: cover;
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -ms-filter: blur(20px);
    -o-filter: blur(20px);
    /* FF doesn't support blur filter, but SVG */
    filter: url("data:image/svg+xml;utf8,<svg height='0' xmlns='http://www.w3.org/2000/svg'><filter id='svgBlur' x='-5%' y='-5%' width='110%' height='110%'><feGaussianBlur in='SourceGraphic' stdDeviation='5'/></filter></svg>#svgBlur");
    filter: "progid: DXImageTransform.Microsoft.Blur(PixelRadius=20)";
    filter: blur(20px);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .csraside .overlay img {
    display: block;
    width: 105%;
    min-width: auto;
    height: 105%;
  }

  .csrsection {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    display: -webkit-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;

    box-shadow: 15px 20px 25px -5px rgba(0, 0, 0, 0.021),
      13px -15px 20px 0px rgba(0, 0, 0, 0.028);
  }

  .csraside img {
    position: static;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-perspective: 1000;
    -moz-perspective: 1000;
    -ms-perspective: 1000;
    perspective: 1000;
  }
}

.csrsection .half-top {
  clear: both;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-620px, 0, 0);
    transform: translate3d(-620px, 0, 0);
  }
}

@keyframes move {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-620px, 0, 0);
    transform: translate3d(-620px, 0, 0);
  }
}

@media only screen and (max-width: 720px) {
  @keyframes move {
    0% {
      transform: translate3d(0, 0, 0);
    }
    100% {
      transform: translate3d(var(--scale, 1250px), 0, 0);
    }
  }
}

.teaser-outer {
  position: relative;
  display: block;
  overflow-x: visible;
  overflow-x: auto;
  /* No width here! */
  height: auto;
  min-height: 200px;
  margin: 0 7%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* Desable the user's mouse selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.teaser ul {
  display: inline-table;
  height: 30%;
  margin: 0;
  padding: 0;
}

.teaser li {
  position: relative;
  display: table-cell;
  height: 30%;
}

.teaser a:hover {
  border-color: #84b7f6;
}

.teaser img {
  display: block;
  display: flex;
  width: 220px;
  height: 150px;
  margin: 5px;

  /* Images fit their container location */
  -o-object-fit: cover;
  object-fit: cover;
  /* The images keep the containers space */
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.csrsection .half-bottom {
  overflow-y: visible;
  overflow-y: auto;
  float: left;
  clear: both;
  flex: 1 0 auto;
  margin: 0 7%;
  padding: 0;
  height: auto;
  min-height: 200px;
  /* Fallback for vertical scroll */
  max-height: 47%;
  /* Absolute calculation */
  max-height: calc(100% - 200px - 3em - calc(9.95744px + 4.25532vw));
}
/* Note: @supports query isn't read by all browsers unfortunatly: this list is out of the scope */
@supports (display: -webkit-box) or (display: box) or (display: -moz-flex) or
  (display: -ms-flex) or (display: -webkit-flex) or (display: flex) {
  .csrsection .half-top {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -moz-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    height: auto;
  }
  .teaser-outer {
    overflow: hidden;
  }
  .teaser {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    display: -webkit-inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-box-orient: horizontal;
    -moz-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -moz-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -moz-box-align: stretch;
    -ms-flex-align: stretch;

    min-height: 1px;

    animation: move 10s alternate-reverse infinite;
  }
  .teaser ul {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .teaser-outer:hover {
    overflow-x: visible;
    overflow-y: auto;
    scroll-behavior: smooth;
    scroll-snap-type: mandatory;
    scroll-snap-destination: 50% 50%;
  }
  .teaser-outer:hover .teaser {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }
  .teaser-outer:hover ul,
  .teaser-outer:focus-within ul {
    position: absolute;
    /* Correction due to translate3d: needs a review */
    top: -1.065em;
    left: 0;
    right: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .csrsection .half-bottom {
    flex: 1 0 auto;
    margin: auto auto;
    padding: 0 5% 0 7%;
    scroll-behavior: smooth;
  }
}

.half-bottom ::-webkit-scrollbar-button {
  background-color: #666;
}

.half-bottom ::-webkit-scrollbar-track {
  background-color: #999;
}

.half-bottom ::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

.half-bottom ::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #999;
  border-radius: 3px;
}

@media only screen and (max-width: 450px) {
  .csraside {
    display: none;
  }
  .csrsection {
    width: 100%;
  }
  /* Show scroll bar by default */
  .teaser-outer {
    overflow-x: visible;
    overflow-y: auto;
  }
  /* Stop animation on user's event */
  .teaser-outer:hover .teaser {
    -webkit-animation-play-state: paused;
    -moz-animation-play-state: paused;
    -o-animation-play-state: paused;
    animation-play-state: paused;
  }
  .teaser-outer:hover ul,
  .teaser-outer:focus-within ul {
    position: absolute;
    /* Correction due to translate3d: needs a review */
    top: -1.1em;
    left: 0;
    right: 0;
    width: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

/**
 * Styling the scroll bars for webkit browsers engine
 */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-button {
  background-color: #666;
}

::-webkit-scrollbar-track {
  background-color: #999;
}

::-webkit-scrollbar-track-piece {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #999;
  border-radius: 12px;
}

.teaser ::-webkit-scrollbar-corner {
  background-color: #999;
}

.teaser ::-webkit-resizer {
  background-color: #666;
}

/* Section 6 - Hover Effect 6 */

.figure {
  background: #1d1d1d;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

.figure * {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.section-5 .figure {
  height: 370px;
}

.section-5 .figure img {
  max-width: 100%;
  position: relative;
  opacity: 0.7;
  height: 100%;
  object-fit: cover;
}

.section-5 .figure figcaption {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 13%;
  right: 13%;
  border: 1px solid rgba(239, 239, 239, 0.8);
  border-width: 0 1px;
  background-color: rgba(29, 29, 29, 0.363);
  color: #efefef;
  /*   width: 220px; */
}

.section-5 .figure h2 {
  padding: 15px 20px;
  margin: 0;
  font-size: 21px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.section-5 .figure h2 span {
  font-weight: 800;
}

.section-5 .figure .icon,
.section-5 .figure .caption {
  overflow: hidden;
  width: 100%;
  position: absolute;
}

.section-5 .figure .icon {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 0;
}

.section-5 .figure .caption {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  bottom: 0;
}

.section-5 .figure p,
.section-5 .figure .icon span {
  display: table;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  text-align: center;
  width: auto;
  color: rgba(239, 239, 239, 0);
  font-size: 0.8em;
}

.section-5 .figure p:before,
.section-5 .figure .icon span:before,
.section-5 .figure p:after,
.section-5 .figure .icon span:after {
  position: absolute;
  height: 1px;
  content: "";
  background: #fff;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.section-5 .figure .icon span:before,
.section-5 .figure .icon span:after {
  width: 1000%;
  top: 50%;
}

.section-5 .figure .icon span:before {
  left: -1000%;
}

.section-5 .figure .icon span:after {
  right: -1000%;
}

.section-5 ion-icon {
  color: #efefef;
  font-size: 30px;
}

.section-5 .figure p:before,
.section-5 .figure p:after {
  width: 150%;
  bottom: 50%;
}

.section-5 .figure p:before {
  left: -100%;
}

.section-5 .figure p:after {
  right: -100%;
}

.section-5 .figure a {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.section-5 .figure:hover img,
.section-5 .figure.hover img {
  opacity: 0.15;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.section-5 .figure:hover p,
.section-5 .figure.hover p {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
  color: #efefef;
}

.section-5 .figure:hover p:before,
.section-5 .figure.hover p:before {
  left: -150%;
}

.section-5 .figure:hover p:after,
.section-5 .figure.hover p:after {
  right: -150%;
}

.hero_img_title {
  height: 70vh;
  object-fit: cover;
}

.headerHeadTitle {
  margin-top: -32vh;
  font-size: 90px;
  font-weight: 900;
}

@media (max-width: 768px) {
  .hero_img_title {
    height: 45vh;
    object-fit: cover;
  }

  .headerHeadTitle {
    margin-top: 40vh;
    font-size: 40px;
  }

  .headerHeadPara {
    font-size: 14px;
  }
}

.input_label {
  color: grey;
}

/* Here's the provided SCSS code converted to normal CSS:

```css */
.display {
  margin-top: 10rem;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(32.5rem, 1fr));
}

.display__img > img {
  width: 100%;
}

.display__content {
  margin-left: 5rem;
}

@media (max-width: 37.5em) {
  .display__content {
    margin-left: 0;
  }
  .display__content--info {
    font-size: 4.8rem;
  }
  .display__content--text {
    font-size: 1.2rem;
  }
}

@media (max-width: 52.25em) {
  /* Add your styles for tab-port here if needed */
}

@media (max-width: 75em) {
  /* Add your styles for tab-land here if needed */
}

@media (min-width: 112.5em) {
  /* Add your styles for big-desktop here if needed */
}

.display__content--info {
  font-size: 5rem;
  line-height: 1.2;
}

.display__content--text {
  margin-top: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.7;
  width: 40rem;
}
